<template>
  <div>
    <h1>URG-RH</h1>
    <h2>{{ pageTitre }}</h2>
    <el-form
      ref="formForgotpw"
      :model="form"
      :rules="rules"
      :validate-on-rule-change="false"
      :hide-required-asterisk="true"
    >
      <el-form-item label="Email" prop="email">
        <el-input ref="email" v-model="form.email" />
      </el-form-item>
      <el-button
        type="primary"
        native-type="submit"
        @click.prevent="forgotPassword"
        >Envoyer</el-button
      >
    </el-form>
    <p>
      <router-link :to="LoginUrl">Login >></router-link>
    </p>
  </div>
</template>

<script>
import { getUserError } from "@/utils/helpers";
import AuthService from "@/services/AuthService";

export default {
  name: "ForgotPassword",
  data() {
    const valideEmail = (rule, value, callback) => {
      if (value) {
        const valStr = value.toString().trim();
        if (!valStr) {
          callback(new Error("Ce champ est obligatoire"));
        } else if (
          /^[A-Z0-9a-z._+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,64}$/.test(valStr)
        ) {
          callback();
        } else {
          callback(new Error("l'adresse email n'est pas valide"));
        }
      } else {
        callback(new Error("Ce champ est obligatoire"));
      }
    };
    return {
      form: { email: this.$route.query.email },
      pageTitre: this.$route.query.new
        ? "Créer votre mot de passe"
        : "Mot de passe oublié",
      error: null,
      message: null,
      rules: {
        email: [{ required: true, validator: valideEmail, trigger: "blur" }],
      },
    };
  },
  mounted() {
    this.$refs.email.focus();
  },
  computed: {
    LoginUrl() {
      return this.form.email ? "/login?email=" + this.form.email : "/login";
    },
  },
  methods: {
    forgotPassword() {
      this.$refs.formForgotpw.validate((valid) => {
        if (valid) {
          this.error = null;
          this.message = null;
          const payload = this.form;
          AuthService.forgotPassword(payload)
            .then(
              () =>
                (this.message =
                  "Le lien de réinitialisation du mot de passe vous a été envoyé par email !")
            )
            .catch((error) => (this.error = getUserError(error)));
        } else {
          return false;
        }
      });
    },
  },
  watch: {
    error() {
      if (this.error) {
        this.$message.error(this.error);
      }
    },
    message() {
      if (this.message) {
        this.$message({
          message: this.message,
          type: "success",
        });
      }
    },
  },
};
</script>
